import { forwardRef, useImperativeHandle, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { Label, Textarea, TextInput } from "flowbite-react";
import { Card, Dialog, LGBDatepicker, SelectCountry } from "../..";
import { IRouteItem, Timestamp } from "../../../types";
import AddContactContent from "./AddContactContent";
import { HiOutlineX, HiOutlineCheckCircle } from "react-icons/hi";
import { getTimeIntervalsForDate } from "../../../static/timeIntervals";
import isSmallScreen from "../../../helpers/is-small-screen";

export interface RouteCardProps {
  routeItem: IRouteItem;
  routeLength: number;
  validationFailed: boolean;
  update: (routeItem: IRouteItem) => void;
  remove: () => void;
}

export interface RouteCardRef {
  getCurrentRouteItem: () => IRouteItem;
}

interface RouteAction {
  type: string;
  payload?: any;
}

const routeReducer = (state: IRouteItem, action: RouteAction): IRouteItem => {
  switch (action.type) {
    case "UPDATE_ITEM":
      return state;
    case "UPDATE_FIELD":
      return {
        ...state,
        location: {
          ...state.location,
          [action.payload.key]: action.payload.value,
        },
      };
    case "UPDATE_DATE":
      return {
        ...state,
        stopDate: Timestamp.fromDate(new Date(action.payload)),
      };
    case "UPDATE_TIMING":
      return {
        ...state,
        timing: action.payload,
      };
    case "ADD_CONTACT":
      return {
        ...state,
        contact: state.contact
          ? [...state.contact, action.payload]
          : [action.payload],
      };
    case "REMOVE_CONTACT":
      return {
        ...state,
        contact: state.contact?.filter(
          (person) => person.phoneNumber !== action.payload.phoneNumber,
        ),
      };
    default:
      return state;
  }
};

const RouteCard = forwardRef<RouteCardRef, RouteCardProps>((props, ref) => {
  const [contactDialogState, setContactDialogState] = useState(false);
  const [timeIntervals, setTimeIntervals] = useState(
    getTimeIntervalsForDate(new Date()),
  );
  const [currentRouteItem, dispatch] = useReducer(routeReducer, {
    ...props.routeItem,
  });
  const { t } = useTranslation(["orders", "validation"]);
  const errorStyle =
    "border-lgb-red-200 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-lgb-red-100 dark:focus:border-red-500 dark:focus:ring-red-500";

  const toggleDialog = () => {
    setContactDialogState(!contactDialogState);
  };

  // Exposing this method to the parent via forwardRef and useImperativeHandle to trigger update only
  // when we want to instaed of on each input change
  useImperativeHandle(ref, () => ({
    getCurrentRouteItem() {
      return currentRouteItem;
    },
  }));

  const customTheme = {
    field: {
      input: {
        base: "dark:bg-lgb-dark-background dark:text-white w-full",
      },
    },
  };

  const updateRouteTiming = (type: number, val1 = "", val2 = "") => {
    switch (type) {
      case 1:
        dispatch({ type: "UPDATE_TIMING", payload: { before: val1 ?? "" } });
        break;
      case 2:
        dispatch({
          type: "UPDATE_TIMING",
          payload: { before: val1 ?? "", after: val2 ?? "" },
        });
        break;
      case 3:
        dispatch({ type: "UPDATE_TIMING", payload: { after: val1 ?? "" } });
        break;
      case 4:
        dispatch({ type: "UPDATE_TIMING", payload: { earliest: true } });
        break;
      case 5:
        dispatch({ type: "UPDATE_TIMING", payload: { latest: true } });
        break;
    }
  };
  const getTimingType = () => {
    switch (true) {
      case "before" in currentRouteItem.timing &&
        "after" in currentRouteItem.timing:
        return 2;
      case "before" in currentRouteItem.timing:
        return 1;
      case "after" in currentRouteItem.timing:
        return 3;
      case "earliest" in currentRouteItem.timing:
        return 4;
      case "latest" in currentRouteItem.timing:
        return 5;
      default:
        return 1;
    }
  };

  return (
    <Card
      type="dashed"
      bgColorClass={
        currentRouteItem.location.id
          ? "bg-lgb-green-100 dark:bg-lgb-on-dark-background"
          : "bg-lgb-yellow-100 dark:bg-lgb-on-dark-background"
      }
      borderClass={
        currentRouteItem.location.id
          ? "dark:border-lgb-green-400 border-1"
          : "dark:border-lgb-yellow-400 border-1"
      }
    >
      <div className="grid grid-cols-2 gap-4 mb-4 items-center">
        <div className="flex justify-start items-center">
          <span className="border bg-lgb-grey-100 dark:bg-lgb-grey-200 dark:border-lgb-grey-600 border-lgb-grey-300 px-4 py-1 font-bold rounded text-sm">
            {t("create.route_selection.stop")} {currentRouteItem.stopNumber + 1}
          </span>
          {!currentRouteItem.location.id && (
            <p className="pl-4 text-lgb-grey-400 dark:text-lgb-grey-400">
              ({t("create.route_selection.not_saved")})
            </p>
          )}
        </div>
        <div>
          <Label
            className="dark:text-gray-900"
            htmlFor="date"
            value={t("create.route_selection.date")}
          />
          <LGBDatepicker
            showError={props.validationFailed && !currentRouteItem.stopDate}
            selectedDate={currentRouteItem.stopDate.toDate() as Date}
            select={(e) => {
              setTimeIntervals(getTimeIntervalsForDate(e));
              dispatch({ type: "UPDATE_DATE", payload: e });
            }}
          />
        </div>
      </div>
      <div className="mb-4">
        {currentRouteItem.location.id && (
          <div className="flex items-center">
            <p className="text-md font-bold lgb-light-text dark:text-lgb-grey-200">
              {currentRouteItem.location.displayName}
            </p>
            <HiOutlineCheckCircle className="text-lgb-green-400 h-5 w-5 ml-4 mr-1 mt-1" />
            <p className="text-lgb-green-400">
              ({t("create.route_selection.saved")})
            </p>
          </div>
        )}
      </div>

      <div
        className={
          "grid gap-4 " + (isSmallScreen() ? "grid-cols-1" : "grid-cols-2")
        }
      >
        <div>
          <div className="mb-4">
            <div className="mb-2 block">
              <Label
                className="dark:text-lgb-grey-400"
                htmlFor="address"
                value={t("create.route_selection.address")}
              />
            </div>
            <TextInput
              theme={customTheme}
              id="address"
              name="address"
              disabled={currentRouteItem.location.id ? true : false}
              autoComplete="off"
              value={currentRouteItem.location.addressLine || ""}
              placeholder={t("create.route_selection.address_ph")}
              required
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "addressLine", value: e.target.value },
                })
              }
              color={
                props.validationFailed &&
                !currentRouteItem.location?.addressLine?.length
                  ? "failure"
                  : undefined
              }
              helperText={
                props.validationFailed &&
                !currentRouteItem.location?.addressLine?.length ? (
                  <p className=" text-sm text-red-600 dark:text-red-500">
                    {t("validation:route_info.address")}
                  </p>
                ) : (
                  ""
                )
              }
            />
          </div>

          <div className="mb-4 grid grid-cols-2 gap-4">
            <div>
              <div>
                <div className="mb-2 block">
                  <Label
                    className="dark:text-lgb-grey-400"
                    htmlFor="postCode"
                    value={t("create.route_selection.postal_code")}
                  />
                </div>
                <TextInput
                  id="postCode"
                  name="postCode"
                  autoComplete="off"
                  theme={customTheme}
                  disabled={currentRouteItem.location.id ? true : false}
                  value={currentRouteItem.location.postCode || ""}
                  placeholder={t("create.route_selection.postal_code_ph")}
                  required
                  type="text"
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_FIELD",
                      payload: { key: "postCode", value: e.target.value },
                    })
                  }
                  color={
                    props.validationFailed &&
                    !currentRouteItem.location?.postCode?.length
                      ? "failure"
                      : undefined
                  }
                  helperText={
                    props.validationFailed &&
                    !currentRouteItem.location?.postCode?.length ? (
                      <p className="absolute text-sm text-red-600 dark:text-red-500">
                        {t("validation:postal_code")}
                      </p>
                    ) : (
                      ""
                    )
                  }
                />
              </div>
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  className="text-lgb-dark-background dark:text-lgb-grey-400"
                  htmlFor="city"
                  value={t("create.route_selection.city")}
                />
              </div>
              <TextInput
                id="city"
                theme={customTheme}
                name="city"
                autoComplete="off"
                value={currentRouteItem.location.city || ""}
                placeholder={t("create.route_selection.city_ph")}
                required
                type="text"
                disabled={currentRouteItem.location.id ? true : false}
                onChange={(e) =>
                  dispatch({
                    type: "UPDATE_FIELD",
                    payload: { key: "city", value: e.target.value },
                  })
                }
                color={
                  props.validationFailed &&
                  !currentRouteItem.location?.city?.length
                    ? "failure"
                    : undefined
                }
                helperText={
                  props.validationFailed &&
                  !currentRouteItem.location?.city?.length ? (
                    <p className=" text-sm text-red-600 dark:text-red-500">
                      {t("validation:route_info.city")}
                    </p>
                  ) : (
                    ""
                  )
                }
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="mb-2 block">
              <Label
                className="dark:text-lgb-grey-400"
                htmlFor="country"
                value={t("create.route_selection.country")}
              />
            </div>
            <div className="w-full mb-2 block">
              <SelectCountry
                id="countryCode"
                disabled={currentRouteItem.location.id ? true : false}
                defaultValue={currentRouteItem.location.countryCode || "NO"}
                onSelectChanged={(e) =>
                  dispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      key: "countryCode",
                      value: e[0],
                    },
                  })
                }
                validation={{
                  id: "countryCode",
                  show: props.validationFailed || false,
                  isInvalid:
                    currentRouteItem.location?.countryCode === undefined,
                  errorMessage: t("common:errors.fields.not_empty"),
                }}
              />
            </div>
          </div>

          <div
            className={
              getTimingType() === 2
                ? "grid grid-cols-4 gap-4"
                : "grid grid-cols-2 gap-4"
            }
          >
            <div
              className={
                "w-full" + (getTimingType() === 2 ? " col-span-2" : "")
              }
            >
              <Label
                className="dark:text-lgb-grey-400"
                htmlFor="timing"
                value={t("create.route_selection.timing")}
              />
              <form>
                <select
                  id="timing"
                  className="w-full border p-2.5 text-sm rounded-lg relative border-gray-300 dark:border-gray-700 dark:border-lgb-grey-600 dark:bg-lgb-dark-background dark:text-white"
                  defaultValue={getTimingType()}
                  required
                  onChange={(e) => updateRouteTiming(+e.target.value)}
                >
                  <option value={1}>
                    {t("create.route_selection.before")}
                  </option>
                  <option value={2}>
                    {t("create.route_selection.before_and_after")}
                  </option>
                  <option value={3}>{t("create.route_selection.after")}</option>
                  <option value={4}>{t("create.route_selection.asap")}</option>
                  <option value={5}>
                    {t("create.route_selection.latest")}
                  </option>
                </select>
              </form>
            </div>

            {getTimingType() !== 4 && getTimingType() !== 5 && (
              <div className="w-full cursor-pointer">
                <Label
                  className="dark:text-lgb-grey-400 "
                  htmlFor="time"
                  value={
                    [1, 2].includes(getTimingType())
                      ? t("create.route_selection.before")
                      : t("create.route_selection.after")
                  }
                />
                <form>
                  <select
                    id="time"
                    className={
                      (props.validationFailed &&
                      ([1, 2].includes(getTimingType())
                        ? !currentRouteItem.timing?.before ||
                          currentRouteItem.timing?.before === ""
                        : !currentRouteItem.timing?.after ||
                          currentRouteItem.timing?.after === "")
                        ? errorStyle
                        : "") +
                      " w-full border p-2.5 text-sm rounded-lg relative border-gray-300 dark:border-lgb-grey-600 dark:bg-lgb-dark-background dark:text-white cursor-pointer"
                    }
                    defaultValue={
                      [1, 2].includes(getTimingType())
                        ? (currentRouteItem.timing?.before as string) || ""
                        : (currentRouteItem.timing?.after as string) || ""
                    }
                    required
                    onChange={(e) =>
                      updateRouteTiming(
                        getTimingType(),
                        e.target.value,
                        getTimingType() === 2
                          ? (currentRouteItem.timing?.after as string) || ""
                          : undefined,
                      )
                    }
                  >
                    <option disabled defaultValue=""></option>
                    {timeIntervals.map((x, idx) => {
                      return (
                        <option value={x} key={idx}>
                          {x}
                        </option>
                      );
                    })}
                  </select>
                </form>
              </div>
            )}

            {getTimingType() === 2 && (
              <div className="w-full">
                <Label
                  className="dark:text-gray-900"
                  htmlFor="latest"
                  value={t("create.route_selection.latest")}
                />
                <form>
                  <select
                    id="latest"
                    className={
                      (props.validationFailed &&
                      (!currentRouteItem.timing?.after ||
                        currentRouteItem.timing?.after === "")
                        ? errorStyle
                        : "") +
                      " w-full border p-2.5 text-sm rounded-lg relative border-gray-300 dark:border-gray-700 dark:border-lgb-grey-600 dark:bg-lgb-dark-background dark:text-white"
                    }
                    required
                    defaultValue={
                      (currentRouteItem.timing?.after as string) || ""
                    }
                    onChange={(e) =>
                      updateRouteTiming(
                        getTimingType(),
                        currentRouteItem.timing?.before as string,
                        e.target.value,
                      )
                    }
                  >
                    <option disabled defaultValue=""></option>
                    {timeIntervals.map((x, idx) => {
                      return (
                        <option value={x} key={idx}>
                          {x}
                        </option>
                      );
                    })}
                  </select>
                </form>
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="text-sm font-semibold text-gray-900 dark:text-lgb-grey-400 pb-3">
            {t("create.route_selection.description")}
          </p>
          <Textarea
            style={{ height: "calc(100% - 32px)" }}
            className="bg-white dark:bg-lgb-dark-background text-gray-900  box-border dark:text-white"
            id="description"
            name="description"
            placeholder={t("create.route_selection.description_ph")}
            required
            value={currentRouteItem.location.description}
            onChange={(e) =>
              dispatch({
                type: "UPDATE_FIELD",
                payload: { key: "description", value: e.target.value },
              })
            }
          />
        </div>
      </div>
      <div>
        {currentRouteItem.contact &&
          currentRouteItem.contact.length > 0 &&
          currentRouteItem.contact.map((person, idx) => {
            return (
              <div
                key={idx}
                className="flex justify-between pt-6 pb-2 w-full border-b dark:border-b-gray-700"
              >
                <div>
                  <p>{person.name}</p>
                </div>
                <div className="flex gap-4 items-center">
                  <p>{person.phoneNumber}</p>
                  <HiOutlineX
                    className="mr-2 h-5 w-5 cursor-pointer"
                    onClick={() =>
                      dispatch({ type: "REMOVE_CONTACT", payload: person })
                    }
                  />
                </div>
              </div>
            );
          })}
        <div
          className={
            "flex " +
            (props.routeLength > 2 ? "justify-between" : "justify-end")
          }
        >
          {props.routeLength > 2 && (
            <p
              className="pt-6 text-sm font-normal underline cursor-pointer hover:text-lgb-grey-500 dark:text-red-800 dark:hover:text-red-700"
              onClick={() => props.remove()}
            >
              {t("create.route_selection.remove")}
            </p>
          )}
          <p
            className="pt-6 text-sm text-lgb-primary hover:text-lgb-blue-400 dark:text-white dark:hover:text-lgb-grey-200 underline font-medium cursor-pointer"
            onClick={() => toggleDialog()}
          >
            {t("create.route_selection.add_contact_title")}
          </p>
        </div>
      </div>
      <Dialog
        title={t("create.contact_info.title")}
        show={contactDialogState}
        toggleModal={() => toggleDialog()}
        hideButtons
        content={
          <AddContactContent
            update={(e) => dispatch({ type: "ADD_CONTACT", payload: e })}
            close={() => toggleDialog()}
          />
        }
      />
    </Card>
  );
});

export default RouteCard;
