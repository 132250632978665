// src/components/molecules/CustomerDetails/CustomerDetails.tsx

import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { getDocs, query, collection, where } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";
import Customer from "../../../models/Customer"; // Corrected default import
import { Tooltip, Button } from "flowbite-react";
import {
    HiOutlineClipboardList,
  HiCheck,
  HiOutlineClock,
  HiOutlineBadgeCheck,
} from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";
import { HiOutlineMapPin } from "react-icons/hi2";


import { Timestamp } from "../../../types";
import {
  noCompletedOrders,
  noUpcomingOrders,
} from "../../atoms/Icons/illustrations";
import { SimpleList } from "../SimpleList";
import InfoCard from "../Cards/InfoCard";
import { IOrder } from "../../../types/order/IOrder"; // Replaced IOrderRoute with IRouteItem
import { OrderStatus } from "../../../types/order/IOrder"; // Corrected import path
import { IRouteItem } from "../../../types";

interface CustomerDetailsProps {
  customer: Customer | undefined;
  edit: () => void;
  delete: () => void;
}

interface IOrderWithId extends IOrder {
  orderId: string;
}

const CustomerDetails: FC<CustomerDetailsProps> = ({
  customer,
  edit,
  delete: deleteCustomer,
}) => {
  const { t } = useTranslation(["common", "customer"]);
  const [hasCopied, setHasCopied] = useState(false);

  // KPIs
  const [totalOrders, setTotalOrders] = useState(0);
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
  const [upcomingOrdersCount, setUpcomingOrdersCount] = useState(0);
  const [uniqueLocationsCount, setUniqueLocationsCount] = useState(0);

  // Orders Data
  const [completedOrders, setCompletedOrders] = useState<
    {
      title: string;
      value: string;
      stopDate: Timestamp | null;
      orderId: string;
    }[]
  >([]);

  const [upcomingOrders, setUpcomingOrders] = useState<
    {
      title: string;
      value: string;
      stopDate: Timestamp | null;
      orderId: string;
    }[]
  >([]);

  const [hasLoadedMoreUpcoming, setHasLoadedMoreUpcoming] = useState(false);
  const [hasLoadedMoreCompleted, setHasLoadedMoreCompleted] = useState(false);
  const [allCompletedOrdersCount, setAllCompletedOrdersCount] = useState(0);
  const [allUpcomingOrdersCount, setAllUpcomingOrdersCount] = useState(0);

  const [activeTab, setActiveTab] = useState<"completed" | "upcoming">(
    "completed",
  );
  const [completedPage, setCompletedPage] = useState(1);
  const [upcomingPage, setUpcomingPage] = useState(1);

  const ordersPerPage = 5;

  const formatTimestamp = (
    timestamp: Timestamp | string | undefined,
  ): string => {
    if (!timestamp) return t("customer:unknown_date");
    if (typeof timestamp === "string") return timestamp;
    return new Date(timestamp.seconds * 1000).toLocaleDateString(); // Converts Firestore Timestamp to a readable date
  };

  // Fetch KPIs and initial orders
  useEffect(() => {
    if (!customer || !customer.workspaceId) return;

    const fetchCustomerData = async () => {
      try {
        // Fetch all orders related to this customer
        const ordersQuery = query(
          collection(firestore, "orders"),
          where("workspaceId", "==", customer.workspaceId),
          where("customerId", "==", customer.id), // Ensure 'customerId' field exists in Firestore
        );

        const querySnapshot = await getDocs(ordersQuery);

        const allMatchedOrders: IOrderWithId[] = querySnapshot.docs.map((doc) => ({
          ...(doc.data() as IOrder),
          orderId: doc.id,
        }));

        // Calculate KPIs
        setTotalOrders(allMatchedOrders.length);
        const completed = allMatchedOrders.filter(
          (order) => order.status === OrderStatus.Completed,
        );
        const upcoming = allMatchedOrders.filter(
          (order) =>
            order.status === OrderStatus.New ||
            order.status === OrderStatus.Scheduled ||
            order.status === OrderStatus.InProgress,
        );

        setCompletedOrdersCount(completed.length);
        setUpcomingOrdersCount(upcoming.length);

        // Calculate unique locations
        const uniqueLocs = new Set<string>();
        allMatchedOrders.forEach((order) => {
          order.route.forEach((routeItem: IRouteItem) => {
            if (routeItem.location && routeItem.location.id) { // Ensure 'location.id' exists
              uniqueLocs.add(routeItem.location.id);
            }
          });
        });
        setUniqueLocationsCount(uniqueLocs.size);

        // Prepare orders for display
        const completedOrdersList = completed.map((order) => ({
          title: Customer.getCustomerName(customer),
          value: `From ${
            order.route[0]?.location?.addressLine ||
            t("customer:unknown_address")
          } to ${
            order.route[order.route.length - 1]?.location?.addressLine ||
            t("customer:unknown_address")
          }`,
          stopDate: order.route[order.route.length - 1]?.stopDate || null,
          orderId: order.orderId,
        }));

        const upcomingOrdersList = upcoming.map((order) => ({
          title: Customer.getCustomerName(customer),
          value: `From ${
            order.route[0]?.location?.addressLine ||
            t("customer:unknown_address")
          } to ${
            order.route[order.route.length - 1]?.location?.addressLine ||
            t("customer:unknown_address")
          }`,
          stopDate: order.route[order.route.length - 1]?.stopDate || null,
          orderId: order.orderId,
        }));

        // Sort orders by latest date
        completedOrdersList.sort(
          (a, b) => (b.stopDate?.seconds || 0) - (a.stopDate?.seconds || 0),
        );
        upcomingOrdersList.sort(
          (a, b) => (b.stopDate?.seconds || 0) - (a.stopDate?.seconds || 0),
        );

        setCompletedOrders(completedOrdersList);
        setUpcomingOrders(upcomingOrdersList);
        setAllCompletedOrdersCount(completedOrdersList.length);
        setAllUpcomingOrdersCount(upcomingOrdersList.length);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        // Optionally, set error state to display a message to the user
      }
    };

    fetchCustomerData();
  }, [customer, t]);

  // Fetch more orders based on pagination
  const fetchOrdersForCustomer = async (
    page: number,
    type: "completed" | "upcoming",
  ) => {
    if (!customer || !customer.workspaceId) return;

    try {
      const ordersQuery = query(
        collection(firestore, "orders"),
        where("workspaceId", "==", customer.workspaceId),
        where("customerId", "==", customer.id),
      );

      const querySnapshot = await getDocs(ordersQuery);

      const allMatchedOrders: IOrderWithId[] = querySnapshot.docs.map((doc) => ({
        ...(doc.data() as IOrder),
        orderId: doc.id,
      }));

      const isCompleted = (status: OrderStatus) =>
        status === OrderStatus.Completed;
      const isUpcoming = (status: OrderStatus) =>
        status === OrderStatus.New ||
        status === OrderStatus.Scheduled ||
        status === OrderStatus.InProgress;

      let filteredOrders: IOrderWithId[] = [];
      if (type === "completed") {
        filteredOrders = allMatchedOrders.filter((order) =>
          isCompleted(order.status),
        );
      } else {
        filteredOrders = allMatchedOrders.filter((order) =>
          isUpcoming(order.status),
        );
      }

      const ordersList = filteredOrders.map((order) => ({
        title: Customer.getCustomerName(customer),
        value: `From ${
          order.route[0]?.location?.addressLine ||
          t("customer:unknown_address")
        } to ${
          order.route[order.route.length - 1]?.location?.addressLine ||
          t("customer:unknown_address")
        }`,
        stopDate: order.route[order.route.length - 1]?.stopDate || null,
        orderId: order.orderId,
      }));

      ordersList.sort(
        (a, b) => (b.stopDate?.seconds || 0) - (a.stopDate?.seconds || 0),
      );

      const start = (page - 1) * ordersPerPage;
      const end = start + ordersPerPage;

      if (type === "completed") {
        if (page === 1) setAllCompletedOrdersCount(ordersList.length);
        const paginated = ordersList.slice(start, end);
        setCompletedOrders((prev) =>
          page === 1 ? paginated : [...prev, ...paginated],
        );
      } else {
        if (page === 1) setAllUpcomingOrdersCount(ordersList.length);
        const paginated = ordersList.slice(start, end);
        setUpcomingOrders((prev) =>
          page === 1 ? paginated : [...prev, ...paginated],
        );
      }
    } catch (error) {
      console.error("Error fetching orders from Firestore:", error);
      // Optionally, set error state to display a message to the user
    }
  };

  const loadMoreOrders = (type: "completed" | "upcoming") => {
    if (type === "completed") {
      const nextPage = completedPage + 1;
      setCompletedPage(nextPage);
      fetchOrdersForCustomer(nextPage, "completed");
      setHasLoadedMoreCompleted(true);
    } else {
      const nextPage = upcomingPage + 1;
      setUpcomingPage(nextPage);
      fetchOrdersForCustomer(nextPage, "upcoming");
      setHasLoadedMoreUpcoming(true);
    }
  };

  // If no customer is selected, show a placeholder
  if (!customer) {
    return (
      <div className="p-4 text-gray-600 dark:text-gray-300">
        {t("customer:split.no_customer_selected")}
      </div>
    );
  }

  return (
    <>
    {/* Customer Header */}
    <div className="xl:flex justify-between items-center gap-4 pt-4 mb-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {Customer.getCustomerName(customer)}
          </p>

          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      Customer.getCustomerName(customer) ?? "",
                    );
                    setHasCopied(true);
                    setTimeout(() => setHasCopied(false), 2000); // Reset after 2 seconds
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
      </div>

      {/* KPIs */}
      <div className="flex flex-col sm:flex-row items-start gap-4 my-6">
        <InfoCard
          icon={<HiOutlineBadgeCheck className="h-7 w-7 text-lgb-grey-600" />}
          title={t("customer:completed_orders")}
          description={`${completedOrdersCount}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600"
          containerClass="bg-lgb-green-100"
        />
        <InfoCard
          icon={<HiOutlineClock className="h-7 w-7 text-lgb-grey-600" />}
          title={t("customer:upcoming_orders")}
          description={`${upcomingOrdersCount}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600"
          containerClass="bg-lgb-yellow-100"
        />
        <InfoCard
          icon={<HiOutlineMapPin className="h-7 w-7 text-lgb-grey-600" />}
          title={t("customer:unique_locations")}
          description={`${uniqueLocationsCount}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600"
          containerClass="bg-lgb-blue-100"
        />
        <InfoCard
          icon={<HiOutlineClipboardList className="h-7 w-7 text-lgb-grey-600" />}
          title={t("customer:total_orders")}
          description={`${totalOrders}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600"
          containerClass="bg-lgb-grey-100"
        />
      </div>

      {/* Basic Info */}
      <SimpleList
        items={[
          {
            title: t("customer:details.email"),
            value: customer.contactEmail || "-",
          },
          {
            title: t("customer:details.phoneNumber"),
            value: customer.phoneNumber || "-",
          },
          {
            title: t(
              customer.organizationNumber
                ? "customer:details.organization_number"
                : "customer:details.social_security_number"
            ),
            value:
              customer.organizationNumber ||
              customer.socialSecurityNumber ||
             "-",
          },
          {
            title: t("customer:details.address"),
            value: customer.primaryAddress?.addressLine || "-",
          },
          {
            title: t("customer:details.city"),
            value: customer.primaryAddress?.city || "-",
          },
          {
            title: t("customer:details.postCode"),
            value: customer.primaryAddress?.postCode || "-",
          },
          
        
        ]}
      />

      {/* Tabs for Orders */}
      <div className="mt-6">
        <p className="text-xl font-medium dark:text-lgb-grey-100 mb-4">
          {t("customer:related_orders")}
        </p>

        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
            <li className="mr-2">
              <button
                onClick={() => setActiveTab("completed")}
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  activeTab === "completed"
                    ? "text-purple-600 border-purple-600"
                    : "text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300"
                }`}
              >
                {t("customer:completed_orders")}
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setActiveTab("upcoming")}
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  activeTab === "upcoming"
                    ? "text-purple-600 border-purple-600"
                    : "text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300"
                }`}
              >
                {t("customer:upcoming_orders")}
              </button>
            </li>
          </ul>
        </div>

        {/* Orders List */}
        <AnimatePresence mode="wait">
          {activeTab === "completed" ? (
            <motion.div
              key="completed"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
            >
              {completedOrders.length === 0 ? (
                <div className="flex flex-col items-center">
                  <div className="mb-8 mt-8">{noCompletedOrders}</div>
                  <p className="text-lgb-grey-500">
                    {t("customer:no_completed_orders")}
                  </p>
                </div>
              ) : (
                <div className="pt-4 pb-4">
                  <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    {completedOrders
                      .slice(
                        0,
                        hasLoadedMoreCompleted
                          ? completedOrders.length
                          : ordersPerPage,
                      )
                      .map(({ title, value, stopDate, orderId }, index) => (
                        <motion.li
                          key={orderId}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={
                            hasLoadedMoreCompleted
                              ? undefined // Prevent exit animation for "Show Less"
                              : { opacity: 0, y: -20 } // Apply exit animation for actual removal
                          }
                          transition={{ duration: 0.3 }}
                          className="pb-3 sm:pb-4"
                        >
                          <Link to={`/orders/all?id=${orderId}`}>
                            <div className="w-full flex items-center justify-between bg-white shadow-custom rounded-lg p-4 dark:bg-darkBlue hover:shadow-customHover dark:hover:bg-lgb-grey-600 cursor-pointer border border-lgb-grey-100 dark:border-lgb-grey-600">
                              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                                <div className="flex-shrink-0">
                                  <HiCheck className="h-6 w-6 text-green-500" />
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {title || t("customer:unknown_customer")}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {value}
                                  </p>
                                </div>
                              </div>
                              <div className="text-sm text-gray-500 whitespace-nowrap">
                                {stopDate
                                  ? formatTimestamp(stopDate)
                                  : t("customer:unknown_date")}
                              </div>
                            </div>
                          </Link>
                        </motion.li>
                      ))}
                  </ul>

                  {/* Toggle Button for Completed */}
                  {completedOrders.length < allCompletedOrdersCount &&
                    !hasLoadedMoreCompleted && (
                      <Button
                        className="mt-4 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                        onClick={() => {
                          loadMoreOrders("completed");
                          setHasLoadedMoreCompleted(true); // Track that user loaded more
                        }}
                      >
                        {t("customer:load_more_completed")}
                      </Button>
                    )}
                  {hasLoadedMoreCompleted && (
                    <Button
                      className="mt-2 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                      onClick={() => {
                        setCompletedOrders((prev) =>
                          prev.slice(0, ordersPerPage),
                        ); // Reset to initial 5
                        setHasLoadedMoreCompleted(false); // Reset the "loaded more" state
                        setCompletedPage(1); // Reset pagination state if necessary
                      }}
                    >
                      {t("customer:show_less")}
                    </Button>
                  )}
                </div>
              )}
            </motion.div>
          ) : (
            <motion.div
              key="upcoming"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              {upcomingOrders.length === 0 ? (
                <div className="flex flex-col items-center">
                  <div className="mb-8 mt-8">{noUpcomingOrders}</div>
                  <p className="text-lgb-grey-500">
                    {t("customer:no_upcoming_orders")}
                  </p>
                </div>
              ) : (
                <div className="pt-4 pb-4">
                  <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    {upcomingOrders
                      .slice(
                        0,
                        hasLoadedMoreUpcoming
                          ? upcomingOrders.length
                          : ordersPerPage,
                      )
                      .map(({ title, value, stopDate, orderId }, index) => (
                        <motion.li
                          key={orderId}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                          className="pb-3 sm:pb-4"
                        >
                          <Link to={`/orders/all?id=${orderId}`}>
                            <div className="w-full flex items-center justify-between bg-white shadow-custom rounded-lg p-4 dark:bg-darkBlue hover:shadow-customHover dark:hover:bg-lgb-grey-600 cursor-pointer border border-lgb-grey-100 dark:border-lgb-grey-600">
                              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                                <div className="flex-shrink-0">
                                  <HiOutlineClock className="h-6 w-6 text-yellow-500" />
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {title || t("customer:unknown_customer")}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {value}
                                  </p>
                                </div>
                              </div>
                              <div className="text-sm text-gray-500 whitespace-nowrap">
                                {stopDate
                                  ? formatTimestamp(stopDate)
                                  : t("customer:unknown_date")}
                              </div>
                            </div>
                          </Link>
                        </motion.li>
                      ))}
                  </ul>

                  {/* Toggle Button for Upcoming */}
                  {upcomingOrders.length < allUpcomingOrdersCount &&
                    !hasLoadedMoreUpcoming && (
                      <Button
                        className="mt-4 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                        onClick={() => {
                          loadMoreOrders("upcoming");
                          setHasLoadedMoreUpcoming(true); // Track that user loaded more
                        }}
                      >
                        {t("customer:load_more_upcoming")}
                      </Button>
                    )}
                  {hasLoadedMoreUpcoming && (
                    <Button
                      className="mt-2 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                      onClick={() => {
                        setUpcomingOrders((prev) =>
                          prev.slice(0, ordersPerPage),
                        ); // Reset to initial 5
                        setHasLoadedMoreUpcoming(false); // Reset the "loaded more" state
                        setUpcomingPage(1); // Reset pagination state if necessary
                      }}
                    >
                      {t("customer:show_less")}
                    </Button>
                  )}
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default CustomerDetails;
