/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect, useMemo } from "react";
import { Button } from "flowbite-react";
import {
  PageLayout,
  TableSkeleton,
  StandardTable,
  DialogForm,
  CompanyModal,
} from "../../components";
import { HiOutlinePlus, HiTrash, HiPencilAlt } from "react-icons/hi";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useCompanies } from "../../hooks";
import { useTranslation } from "react-i18next";
import { ITableRow, ICompany } from "../../types";
import { Company } from "../../models";
import { EmptyState } from "../../components";
import { emptyCompanyIllustration } from "../../components/atoms/Icons/illustrations";

export const CompaniesPage: FC = function () {
  const [isUpsertModalOpen, openUpsertodal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [companyData, setCompanyData] = useState(Company.default());
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const query = useCompanies(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "company"]);

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  const companies = useMemo(() => query.data ?? [], [query]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        companies.map((company) => {
          return {
            id: company.id,
            cells: [
              {
                id: "name",
                children: company.name,
                showOnSmallScreen: true,
              },
              {
                id: "organizationNumber",
                children: company.organizationNumber,
              },
              {
                id: "email",
                children: company.contactEmail,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setCompanyData(company);
                  openUpsertodal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setCompanyData(company);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, companies]);

  return (
    <PageLayout>
      <div className="relative pb-20">
        {tableRows.length === 0 ? null : (
          <div>
            <div>
              <div className="flex flex-col sm:flex-row justify-between items-start p-4">
                <div>
                  <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                    {t("company:title")}
                  </h1>
                  <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
                    {t("company:description")}
                  </p>
                </div>
                <Button
                  color="light"
                  onClick={() => {
                    setCompanyData(Company.default());
                    openUpsertodal(true); // Correct function name
                  }}
                  type="submit"
                  className="mt-2 sm:mt-0 mb-4 sm:mb-0" // Adjusted top and bottom margins
                >
                  <HiOutlinePlus className="mr-2 h-5 w-5" />
                  {t("company:new_company")}
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow text:white">
                {isLoading ? (
                  <TableSkeleton />
                ) : tableRows.length === 0 ? (
                  // Render empty state when there are no table rows
                  <EmptyState
                    icon={emptyCompanyIllustration}
                    title={t("company:empty_state.title")}
                    description={t("company:empty_state.description")}
                    buttonText={t("company:empty_state.button_text")}
                    buttonClick={() => {
                      setCompanyData(Company.default());
                      openUpsertodal(true);
                    }}
                  />
                ) : (
                  <StandardTable
                    header={[
                      {
                        id: "name",
                        children: t("common:name"),
                        showOnSmallScreen: true,
                      },
                      {
                        id: "organizationNumber",
                        children: t("common:organization_number"),
                      },
                      {
                        id: "email",
                        children: t("common:email"),
                      },
                    ]}
                    hasActions={true}
                    rows={tableRows}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CompanyModal
        data={companyData}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: ICompany) => {
          if (Company.isNew(companyData))
            Company.create(activeWorkspace?.workspaceId ?? "", formData);
          else Company.update(companyData, formData);
          openUpsertodal(false);
          updateCacheKey();
        }}
        onCancel={() => openUpsertodal(false)}
      />

      <DialogForm
        title={t("company:delete_company") + " " + companyData.name + "?"}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        dismissible
        buttonConfirmColor="failure"
        confirmButton={() => {
          Company.delete(companyData);
          openDeleteModal(false);
          updateCacheKey();
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};
